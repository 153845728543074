.react-datepicker__input-container {
  position: relative;
}
.react-datepicker__input-container .react-datepicker__calendar-icon {
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 576px) {
  .modal{
    .modal-dialog{
      width: $modal-lg;
      max-width: $modal-lg;
    }
    .modal-dialog.modal-sm{
      width: $modal-lg;
      max-width: $modal-sm;
    }
  }
}